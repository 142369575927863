import ApiService from "@/core/services/api.service";
import { getAccess } from "@/core/services/user.group.access.service";

// action types
export const API_SECURITY_GROUPS_ACTION_LOAD = "action_admin_security_groups_load";
export const API_SECURITY_GROUPS_ACTION_LOAD_AVAILABLE_SECURITIES = "action_admin_security_groups_load_avaliable_securities";
export const API_SECURITY_GROUPS_ACTION_LOAD_CURRENT_SECURITIES = "action_admin_security_groups_load_current_securities";
export const API_SECURITY_GROUPS_ACTION_ADD_OR_EDIT_GROUP = "action_admin_security_groups_add_or_edit_group";
export const API_SECURITY_GROUPS_ACTION_GET_SECURITY = "action_admin_security_groups_get_security";

// muts
export const API_SECURITY_GROUPS_MUTS_SET_PAGE_PARAMS = "muts_admin_security_groups_set_page_params";
export const API_SECURITY_GROUPS_MUTS_SET_SECURITIES = "muts_admin_security_groups_set_securities";
export const API_SECURITY_GROUPS_MUTS_SET_SECURITY_GROUP = "muts_admin_security_groups_set_security_group";
export const API_SECURITY_GROUPS_MUTS_SET_CURRENT_SECURITIES = "muts_admin_security_groups_set_current_securities";
export const API_SECURITY_GROUPS_MUTS_SET_STATUS = "muts_admin_security_groups_set_status";

const state = {
    page_params: {
        security_groups: [
            { id: null, name: '', description: ''}
        ],
        page_number: null,
        page_size: null,
        total_items: null
    },
    securities: [
        {
            id: '',
            name: '',
            securities: [
                {
                    description: '',
                    explanation: '',
                    security_id: '',
                    site_id: ''
                }
            ]
        }
    ],
    currentSecurities: [
        {
            id: '',
            name: '',
            securities: [
                {
                    description: '',
                    explanation: '',
                    security_id: '',
                    site_id: ''
                }
            ]
        }
    ],
    status: {
        status: '',
        description: ''
    },
    security_group: {
        id: null,
        name: '',
        description: ''
    },
    permission_to_continue: false
}

const getters = {
    getAdminSecurityGroupsPageParams() {
        return state.page_params;
    },
    getAdminSecurityGroupsStatus() {
        return state.status;
    },
    getAdminSecurityGroupsSecurities() {
        return state.securities;
    },
    getAdminSecurityGroupsCurrentSecurities() {
        return state.currentSecurities;
    },
    getAdminSecurityGroupsSecurityGroup() {
        return state.security_group;
    },
    getAdminSecurityGroupsPermissionToContinue() {
        return state.permission_to_continue;
    }
}

const actions = {
    [API_SECURITY_GROUPS_ACTION_LOAD](context, params) {
        params = params ? params : { };
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/security_groups/get", params, context)
                    .then(({ data }) => {
                        // console.log('data in API_SECURITY_GROUPS_ACTION_LOAD');
                        // console.log(data);
                        context.commit(API_SECURITY_GROUPS_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_SECURITY_GROUPS_ACTION_LOAD_AVAILABLE_SECURITIES](context, params) {
        params = params ? params : [{ }];
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/security_groups/get_avaliable_securities", params, context)
                    .then(({ data }) => {
                        // console.log('data in API_SECURITY_GROUPS_ACTION_LOAD_AVALIABLE_SECURITIES');
                        // console.log(data);
                        context.commit(API_SECURITY_GROUPS_MUTS_SET_SECURITIES, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_SECURITY_GROUPS_ACTION_LOAD_CURRENT_SECURITIES](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/security_groups/get_current_group_securities/" + params, context)
                    .then(({ data }) => {
                        // console.log('data in API_SECURITY_GROUPS_ACTION_LOAD_CURRENT_SECURITIES');
                        // console.log(data);
                        context.commit(API_SECURITY_GROUPS_MUTS_SET_CURRENT_SECURITIES, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_SECURITY_GROUPS_ACTION_ADD_OR_EDIT_GROUP](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/security_groups/save_changes", params, context)
                    .then(({ data }) => {
                        // console.log('data in API_SECURITY_GROUPS_ACTION_ADD_OR_EDIT_GROUP');
                        // console.log(data);
                        context.commit(API_SECURITY_GROUPS_MUTS_SET_STATUS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_SECURITY_GROUPS_ACTION_GET_SECURITY](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/security_groups/get_by_id/" + params, context)
                    .then(({ data }) => {
                        // console.log('data in API_SECURITY_GROUPS_ACTION_LOAD_CURRENT_SECURITIES');
                        // console.log(data);
                        context.commit(API_SECURITY_GROUPS_MUTS_SET_SECURITY_GROUP, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

}

const mutations = {
    [API_SECURITY_GROUPS_MUTS_SET_PAGE_PARAMS](state, {data}) {
        // console.log('data in API_SECURITY_GROUPS_MUTS_SET_PAGE_PARAMS');
        // console.log(data);
        state.page_params.security_groups = data.security_groups;
        state.page_params.page_number = data.page_number;
        state.page_params.page_size = data.page_size;
        state.page_params.total_items = data.total_items;
        // console.log(state.page_params.security_groups);
    },
    [API_SECURITY_GROUPS_MUTS_SET_SECURITIES](state, {data}) {
        // console.log('data in API_SECURITY_GROUPS_MUTS_SET_SECURITIES');
        // console.log(data);
        state.securities = data.securities;
        // console.log(state.securities);
    },
    [API_SECURITY_GROUPS_MUTS_SET_STATUS](state, {data}) {
        state.status = data.status;
        state.security_group.id = data.id;
        state.permission_to_continue = data.permission_to_continue;
        // console.log(state.status);
    },
    [API_SECURITY_GROUPS_MUTS_SET_CURRENT_SECURITIES](state, {data}) {
        // console.log('data in API_SECURITY_GROUPS_MUTS_SET_CURRENT_SECURITIES');
        // console.log(data);
        state.currentSecurities = data.securities;
        // console.log(state.currentSecurities);
    },
    [API_SECURITY_GROUPS_MUTS_SET_SECURITY_GROUP](state, {data}) {
        // console.log('data in API_SECURITY_GROUPS_MUTS_SET_CURRENT_SECURITIES');
        // console.log(data);
        state.security_group = data.security_group;
        // console.log(state.currentSecurities);
    },


}

export default {
    state,
    actions,
    mutations,
    getters
};